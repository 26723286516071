/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import Rails from '@rails/ujs';
import Routes from './utils/routes.js.erb';

window.Routes = document.Routes = Routes;
// need to add this to make data-method attr on links working
Rails.start();

if ('scrollRestoration' in history) {
  history.scrollRestoration = 'manual';
}

// scroll to position on start
document.addEventListener('turbolinks:load', function () {
  if (!window.location.hash) {
    return;
  }
  const targetElement = document.querySelector(window.location.hash);
  if (targetElement) {
    // Scroll to top first to reset the scroll position
    window.scrollTo(0, 0);
    const observer = new IntersectionObserver(_entries => {
      targetElement.scrollIntoView({ behavior: 'instant' });
      observer.disconnect();
    });

    observer.observe(targetElement);
  }
});
